import React from 'react';
import styled from 'styled-components';
import { ContentfulPageContactUs } from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper';
import {
  RegistrationForm,
  RichText,
  Spacer,
  TextBlock,
  TwoColumnRichText,
  Undefined,
  RichTextColumns,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

const RichTextContainer = styled.div`
  p:not(:first-of-type) {
    margin-top: ${(props) => props.theme.spacing.xs};
  }
`;

interface PageContactUsProps {
  data?: ContentfulPageContactUs;
}
const PageContactUs: React.FC<PageContactUsProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <div className="pb-lg">
      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;
        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_FORM:
            element = <RegistrationForm key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT:
            element = <RichText key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_SPACER:
            element = <Spacer key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TEXT_BLOCK:
            element = <TextBlock key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TWO_COLUMN_RICHTEXT:
            element = (
              <TwoColumnRichText key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_RICH_TEXT_COLUMNS:
            element = <RichTextColumns key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}

      {/* <ComponentMapper contentfulComponents={data.sections} /> */}

      {/* {data?.offices && data?.offices.length > 0 && (
        <BackgroundWrapper data={data?.background}>
          <div className="container lg:flex lg:gap-x-lg lg:justify-center">
            {data.offices.map((office, index) => {
              return (
                <div
                  key={`office-${office.id}-${index}`}
                  className="mt-md first:mt-0 lg:mt-0"
                >
                  {office.title && (
                    <Typography
                      as="h2"
                      variant="h2"
                      className="text-primaryMain"
                    >
                      {office.title}
                    </Typography>
                  )}
                  {office.description?.copy?.raw && (
                    <RichTextContainer className="mt-xs">
                      <Typography variant="body">
                        {renderRichText(office.description?.copy?.raw)}
                      </Typography>
                    </RichTextContainer>
                  )}
                </div>
              );
            })}
          </div>
        </BackgroundWrapper>
      )} */}
    </div>
  );
};
export default PageContactUs;
